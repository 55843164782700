@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Outfit", sans-serif;
  }
}

@layer components {
  /* default CSS  */
  .defaultButton {
    @apply bg-slate-700 dark:bg-slate-600 transition-all duration-500 text-white font-Outfit font-medium text-sm rounded-md inline-flex items-center justify-center py-3 px-7;
  }

  .submitButton {
    @apply mt-5 block ml-auto;
  }

  .side-menubar {
    @apply bg-white w-72 shadow-sm z-20 relative;
  }

  /* Default Sidebar menu */
  .sidebarNav {
    @apply fixed top-[80px] overflow-hidden md:top-0 left-0 bottom-0 flex flex-col bg-white dark:bg-slate-800 transition-all duration-500 z-50;
  }

  .sidebar-logo-area {
    @apply w-full hidden items-center justify-between px-6 pt-[23px] pb-6 border-b border-r md:border-slate-100 dark:md:border-slate-900  md:flex;
  }

  .brand-text {
    @apply font-Outfit text-2xl text-black dark:text-white font-medium pl-3 transition-all duration-700 whitespace-nowrap;
  }

  .toggle-btn {
    @apply inline-block;
  }
  /* pl-16 to pl-8 */
  .sub-menu {
    @apply pl-[28px] max-h-0 transition-all duration-500 w-full overflow-hidden;
  }
  .nested-sub-menu {
    @apply pl-[19px] max-h-0 transition-all duration-500 w-full overflow-hidden;
  }


  .nav-item {
    @apply flex items-center px-4 py-2 rounded cursor-pointer relative transition-all duration-500 whitespace-nowrap;
  }

  .nav-item-text {
    @apply text-base ml-3 inline-block;
  }

  .nav-icon {
    @apply ml-auto w-5 h-5 items-center justify-center rounded-full transition-all duration-500;
  }

  .nav-item-default {
    @apply bg-white dark:bg-slate-800 duration-500 text-textColor dark:text-white;
  }

  .nav-item-selected {
    @apply bg-black dark:bg-slate-600 text-white dark:text-white;
  }
/* changed bg */
  .nav-icon-selected {
    @apply text-white bg-slate-800 dark:text-black dark:bg-slate-900;
  }

  .nav-icon-default {
    @apply bg-slate-100 text-textColor dark:text-white dark:bg-slate-600;
  }

  /* Sidebar Menu Collapse */
  .menu-collapse {
    @apply w-0 md:w-20;
  }

  .menu-collapse .brand-text {
    @apply hidden opacity-0;
  }

  .menu-collapse .toggle-btn {
    @apply hidden;
  }

  .menu-collapse .sub-menu {
    @apply h-0 hidden;
  }

  .menu-collapse .nav-icon {
    @apply hidden;
  }

  /* Collapse Menu Hover */
  .menu-collapse:hover {
    @apply w-64 md:w-72;
  }

  .menu-collapse:hover .nav-icon {
    @apply inline-flex;
  }

  .menu-collapse:hover .toggle-btn {
    @apply inline-block duration-1000;
  }

  .menu-collapse:hover .sub-menu {
    @apply h-auto block;
  }

  .menu-collapse:hover .brand-text {
    @apply inline-block opacity-100;
  }

  .menu-collapse:hover .nav-icon {
    @apply inline-flex;
  }

  /* Sidebar Menu Expanded */
  .menu-expand {
    @apply w-64 md:w-72;
  }

  .menu-expand .sidebar-logo-area {
    @apply w-full items-center justify-between px-6 pb-6 md:flex hidden;
  }

  .menu-expand .brand-text {
    @apply font-Outfit text-2xl font-medium pl-3 transition-all duration-700;
  }

  .menu-expand .toggle-btn {
    @apply inline-block;
  }

  .menu-expand .nav-item {
    @apply flex items-center px-4 py-2 rounded cursor-pointer relative transition-all duration-500;
  }

  .menu-expand .nav-icon {
    @apply ml-auto w-5 h-5 inline-flex items-center justify-center rounded-full transition-all duration-500;
  }

  /* other */

  .nav-link {
    @apply py-2 text-[15px] text-slate-400 font-Outfit font-medium block capitalize hover:text-black hover:dark:text-white hover:tracking-wider transition-all duration-500 whitespace-nowrap relative before:w-[10px] before:h-[10px] before:rounded-full before:border-2 dark:before:border-textColor before:absolute before:-left-5 before:top-[13px] hover:before:border-black dark:hover:before:border-white before:transition-all before:duration-500;
  }

  .active {
    @apply text-black tracking-wider before:border-black dark:text-white dark:before:border-white;
  }

  .topbar-icon {
    @apply text-xl mt-1;
  }

  .country-list {
    @apply px-3 py-1 flex;
  }

  .dropdown-list {
    @apply absolute right-0 shadow-md shadow-slate-300 w-44 md:w-full overflow-hidden bg-white
        dark:bg-slate-800 dark:shadow-slate-900 rounded-md transition-all duration-500;
  }

  .dropdown-option {
    @apply inline-block relative -top-1 font-Outfit text-textColor font-normal text-base pl-1 dark:text-white;
  }

  .dropdown-select .active {
    @apply bg-slate-100 dark:bg-slate-700;
  }
  .dropdown-list .active {
    @apply bg-slate-100 dark:bg-slate-700;
  }

  .header-link {
    @apply mr-7 font-Outfit font-medium text-black text-base w-8 h-8 rounded-full bg-[#F7F8FC] flex items-center justify-center cursor-pointer;
  }

  .user-option {
    @apply block font-Outfit text-base font-medium text-textColor hover:text-black hover:tracking-wider transition-all duration-500;
  }

  .check {
    @apply ring-1 ring-offset-2 ring-black;
  }

  /* Content Body Area CSS */

  .breadcrumbList {
    @apply inline-block text-textColor dark:text-slate-300 font-Outfit font-normal text-base mr-3 relative;
  }

  .breadcrumbIcon {
    @apply text-xs pl-1 relative top-[1px] text-textColor dark:text-white;
  }

  .breadcrumbActive {
    @apply before:hidden text-black dark:text-white mr-0 pr-0;
  }

  .inputField {
    @apply py-2 px-4 border border-slate-200 w-full sm:w-40 lg:w-48 font-Outfit font-medium text-textColor text-sm outline-none focus:outline-none focus:border-slate-400 !ring-0 rounded-md transition-all duration-500 dark:bg-slate-900 dark:border-slate-900 dark:text-white dark:placeholder:text-white;
  }

  /* table Area CSS */
  .table-head {
    @apply py-4 px-4 font-Outfit text-sm text-textColor bg-[#F8F8F8] dark:bg-slate-700 dark:text-white font-medium whitespace-nowrap;
  }

  .table-cell {
    @apply p-4 font-Outfit text-sm text-textColor bg-white dark:bg-slate-800 font-medium whitespace-nowrap dark:text-white;
  }

  .table-cell .customer-img {
    @apply w-6 h-6 rounded-full;
  }

  .table-cell .payment-status {
    @apply py-[6px] px-5 rounded-2xl;
  }

  .table-cell .badge-mini {
    @apply pb-[3px] px-2 rounded-xl;
  }

  .table-cell .success {
    @apply bg-green-100 text-green-500;
  }

  .table-cell .due {
    @apply bg-orange-100 text-orange-500;
  }

  .table-cell .danger {
    @apply bg-red-100 text-red-500;
  }

  .table-cell .action-btns a {
    @apply w-7 h-7 border border-slate-200 rounded-md inline-flex items-center justify-center text-base text-black hover:bg-slate-900 hover:text-white transition-all duration-500 dark:border-slate-900 dark:text-white dark:hover:bg-slate-100 dark:hover:text-black;
  }

  .pagination-list {
    @apply select-none inline-flex items-center justify-center w-8 h-8 rounded-md font-Outfit font-normal text-sm text-textColor bg-slate-100 hover:bg-slate-900 hover:text-white transition-all duration-500 dark:text-white dark:bg-slate-700 dark:hover:bg-slate-900;
  }

  .pagination-list-disabled {
    @apply select-none inline-flex items-center justify-center w-8 h-8 rounded-md font-Outfit font-normal text-sm text-textColor bg-slate-100   transition-all duration-500 dark:text-white dark:bg-slate-700 ;
  }

  .pagination-active {
    @apply bg-slate-900 text-white dark:bg-slate-900;
  }

  .dropdownTableSelect {
    @apply w-14 h-9 text-sm font-Outfit bg-transparent border flex rounded-md border-slate-200 !bg-none py-0 relative px-2 focus:!border-slate-200 focus:!outline-none !shadow-none !ring-0 mr-2 z-10 dark:border-slate-900 dark:bg-slate-500 dark:text-white dark:focus:!border-slate-900;
  }

  .dropdownTableSelect option {
    @apply border-0 shadow-none ring-0 outline-none bg-white h-4 font-Outfit font-medium dark:bg-slate-800;
  }

  /* Side Bar Scrool Bar */
  .styled-scrollbars::-webkit-scrollbar {
    width: 5px;
    /* height: 10px;  */
  }
  .styled-scrollbars::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  .styled-scrollbars::-webkit-scrollbar-track {
    background-color: white;
  }
  /* Forms Area  */
  .input-group {
    @apply relative;
  }

  .inputField2 {
    @apply py-3 px-4 border border-slate-200 w-full sm:w-40 lg:w-48 font-Outfit font-medium text-textColor text-sm outline-none focus:outline-none focus:border-slate-400 !ring-0 rounded-md transition-all duration-500 dark:bg-slate-700 dark:border-slate-900 dark:text-white placeholder:text-slate-300 dark:placeholder:text-slate-500;
  }



  .inputLabel {
    @apply font-Outfit font-medium text-sm text-textColor pb-2 inline-block dark:text-white;
  }

  .disableInput {
    @apply disabled:bg-slate-200 dark:disabled:bg-slate-700 cursor-not-allowed;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 99;
  }

  .checkbox-style {
    @apply rounded-sm ring-2 !ring-transparent ring-offset-2 !outline-none checked:border-slate-900 checked:!bg-slate-900 checked:!ring-slate-400 dark:bg-slate-700 dark:!shadow-slate-900 dark:checked:border-slate-100 dark:checked:!bg-slate-900 dark:checked:!ring-slate-800;
  }

  .inputText {
    @apply font-Outfit text-sm font-medium text-textColor dark:text-white;
  }

  .primaryCheckbox {
    @apply rounded-sm ring-2 !ring-transparent ring-offset-2 !outline-none checked:border-indigo-900 checked:!bg-indigo-900 checked:!ring-indigo-400 dark:bg-indigo-700 dark:!shadow-indigo-900 dark:checked:border-indigo-100 dark:checked:!bg-indigo-900 dark:checked:!ring-indigo-800;
  }

  .disable {
    @apply disabled:bg-slate-200 cursor-not-allowed text-slate-200;
  }

  .radioInput {
    @apply w-4 h-4 ring-2 !ring-transparent ring-offset-2 !outline-none checked:border-slate-900 checked:!bg-slate-900 checked:!ring-slate-400 dark:bg-slate-700 dark:!shadow-slate-900 dark:checked:border-slate-100 dark:checked:!bg-slate-900 dark:checked:!ring-slate-800;
  }

  /* Side Bar Scrool Bar */
  .styled-scrollbars::-webkit-scrollbar {
    width: 5px;
    /* height: 10px;  */
  }
  .styled-scrollbars::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  .styled-scrollbars::-webkit-scrollbar-track {
    background-color: white;
  }

  /* Alert */
  .alert {
    @apply relative flex items-center justify-between w-full px-5 py-3 rounded-md font-Outfit font-medium;
  }

  .alert.danger {
    @apply bg-red-100 text-red-500;
  }
  .alert.success {
    @apply bg-green-100 text-green-500;
  }

  /* Profile */

  .profileContactList {
    @apply absolute left-0 w-24 text-black dark:text-white
  }


  /* Role Permission */

  /* .rolePermission {
    @apply ;
  } */

  .rolePermission .permissionCardHead {
    @apply flex items-center justify-between bg-slate-100 px-5 py-3;
  }

  .rolePermission .permissionCardHead .permissionCardTitle {
    @apply font-Outfit font-semibold text-lg;
  }

  .rolePermission ul {
    @apply px-5 overflow-hidden transition-all duration-500 shadow-sm border border-slate-100;
  }

  .rolePermission .permissionCardList .checkbox-style {
    @apply z-10 border-slate-800;
  }

  .rolePermission .permissionCardList .inputText {
    @apply text-lg;
  }

  .permissionCardList {
    @apply pl-4 py-1 relative before:w-[1px] before:h-full before:absolute before:bg-slate-500 before:left-0 before:top-0 after:w-3 after:h-3 after:rounded-full after:absolute after:top-1/2 after:-left-[6px] after:bg-slate-500 last:before:h-1/2 first:before:h-1/2 first:before:top-1/2 after:-translate-y-1/2;
  }

  .singlePermissionCardList {
    @apply before:!h-0 
  }




 /* ApexChart js  dark mode changed*/
 .statisticsChartCard {
  @apply pt-4 px-5 bg-sky-50 rounded-md dark:bg-slate-700;
}

.statisticsChartCard h5 {
  @apply text-textColor  dark:text-zinc-200 text-sm font-Outfit font-normal;
}

.statisticsChartCard h3 {
  @apply text-xl text-black dark:text-zinc-50 font-Outfit font-medium py-1;
}
.statisticsChartCard p {
  @apply font-light font-Outfit text-sm text-textColor dark:text-zinc-50;
}


/* Customer Card */
.customer-card {
  @apply rounded-md px-4 pb-4 text-center mt-8 pt-4
}

.customer-card-img {
  @apply relative w-16 h-16 rounded-full text-center mx-auto -mt-8
}

.customer-card-img img {
  @apply w-16 h-16 rounded-full border-2 border-yellow-500
}

.customer-card-img .MVPCustomer {
  @apply absolute -top-7 left-1/2 -translate-x-1/2 text-[2.5rem] text-yellow-500
}

.customer-card-img .customer-position {
  @apply flex items-center justify-center absolute bottom-0 right-0 w-5 h-5 rounded-full bg-yellow-500 text-white font-Outfit font-normal text-sm 
}

.customer-card h3 {
  @apply font-Outfit font-medium text-base text-textColor pt-3 pb-2
}

.customer-card p {
  @apply py-1 px-4 inline-block font-Outfit font-normal text-xs text-white bg-slate-900 rounded-3xl 
}

.progressBar {
  @apply mt-3
}

.progressBar .progressData {
  @apply flex justify-between items-center font-Outfit text-slate-700 font-medium text-sm
}

.progressBar .progressBarArea {
  @apply mt-2
}

.progressBar .progressBarArea div {
  @apply rounded h-2 text-center
}

 /* general-settings */
 .generalSettings .generalSettingsCardHead {
  @apply flex items-center justify-between bg-slate-100 dark:bg-slate-700 px-5 py-3;
}

.generalSettings .generalSettingsCardHead .generalSettingsCardTitle {
  @apply font-Outfit font-semibold text-lg dark:text-white;
}

.generalSettings .settingBox {
  @apply px-5 overflow-hidden transition-all duration-500 shadow-sm border border-slate-100 border-t-0 dark:border-slate-900;
}

.generalSettings .generalSettingsCardList .checkbox-style {
  @apply z-10 border-slate-800;
}

.generalSettings .generalSettingsCardList .inputText {
  @apply text-lg;
}

.generalSettingsCardList {
  @apply pl-4 py-1 relative before:w-[1px] before:h-full before:absolute before:bg-slate-500 before:left-0 before:top-0 after:w-3 after:h-3 after:rounded-full after:absolute after:top-1/2 after:-left-[6px] after:bg-slate-500 last:before:h-1/2 first:before:h-1/2 first:before:top-1/2 after:-translate-y-1/2;
}

.imageUploadCard {
  @apply bg-white dark:bg-slate-900 border border-slate-500 border-opacity-20 dark:border-slate-900 border-b-0 rounded-md shadow-md
}

.imageUploadCardHeader {
  @apply py-4 pl-4 pr-3 border-b border-slate-300 border-opacity-30
}

.imageUploadCardHeader h3 {
  @apply font-Outfit text-lg text-slate-900 font-semibold dark:text-white
}

}

/* Sweet Alert Dark Mode */
.swal-modal {
  @apply dark:!bg-slate-700;
}

.swal-title {
  @apply dark:!text-white;
}

.swal-text {
  @apply dark:!text-white;
}


.swal-icon--success:after, .swal-icon--success:before {
  @apply dark:!bg-slate-700 dark:!border-none;
}
.swal-icon--success__hide-corners{
  @apply dark:!bg-slate-700 dark:!border-none;
}

.Toastify__toast-body{
  @apply font-Outfit
}